<template>
    <section class="testimonial-details">
        <tool-bar v-bind:lang.sync="lang">
            {{ mode }} a news article
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-if="mode === 'Create'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                    <button class="button"
                            v-if="mode === 'Update'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="update">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Update</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="container py-5 testimonial">
            <div class="row">
                <div class="col-md-4 col-lg-3 d-flex justify-content-center align-items-center">
                    <div class="mb-3 mr-md-3 item-image is-200x200 position-relative">
                        <button class="button edit"
                                v-on:click="setThumbnailImage"
                                v-on:mouseover="revealImageElement"
                                v-on:mouseout="revealImageElement">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="image"></font-awesome-icon>
                                </span>
                            <span>Change Thumbnail</span>
                        </button>
                        <img class="img-fluid"
                             v-bind:src="thumbnail">
                    </div>
                </div>
                <div class="col-md-8 col-lg-9 d-flex flex-column justify-content-center">
                    <h3 class="mt-0 mb-3 text-center text-md-left"
                        v-html="title">
                    </h3>
                    <p v-line-clamp:20="3">
                        {{ content.replace(/<[^>]*>/g, "") }}
                    </p>
                </div>
            </div>

            <div>
                <medium-editor class="text-center mb-5 editable"
                               custom-tag="h2"
                               v-bind:text="title"
                               v-bind:options="Object.assign({ placeholder: { text: 'Title', } }, options)"
                               v-on:edit="processTitleEditOperation">
                </medium-editor>

                <div class="row mb-5">
                    <div class="col-md-6 mx-auto d-block position-relative">
                        <button class="button edit"
                                v-on:click="setTitleImage"
                                v-on:mouseover="revealImageElement"
                                v-on:mouseout="revealImageElement">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="image"></font-awesome-icon>
                                </span>
                            <span>Change Title Image</span>
                        </button>
                        <img class="img-fluid"
                             v-bind:src="titleImage">
                    </div>
                </div>

                <editor v-model="content"></editor>
            </div>
        </div>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isThumbnailModalActive">
            <modal-set-image v-bind:file.sync="thumbnailFile">
                Configure News Thumbnail
            </modal-set-image>
        </b-modal>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isTitleImageModalActive">
            <modal-set-image v-bind:file.sync="titleImageFile">
                Configure News Title Image
            </modal-set-image>
        </b-modal>
    </section>
</template>

<script>
import editor from "vue2-medium-editor";

import ModalSetImage from "../modals/ModalSetImage";
import ToolBar from "../layouts/ToolBar";
import Editor from "../Editor";

export default {
    name: "NewsDetails",
    components: {
        Editor,
        ToolBar,
        ModalSetImage,
        "medium-editor": editor
    },
    props: {
        "newsId": {
            type: String,
        },
    },
    data () {
        return {
            loading: false,
            edited: false,
            lang: "en",

            isImageModalActive: false,
            isThumbnailModalActive: false,
            isTitleImageModalActive: false,

            mode: "Create",
            options: {
                toolbar: {
                    buttons: ["bold", "italic", "underline", "strikethrough"]
                }
            },

            titleInComponent: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            contentInComponent: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            thumbnail: "",
            thumbnailFile: null,

            titleImage: "",
            titleImageFile: null,

            images: [],
            imageFiles: []
        }
    },
    computed: {
        news () {
            return this.$store.state.mall.news;
        },

        title: {
            get () {
                return this.titleInComponent[this.lang];
            },
            set (val) {
                this.titleInComponent[this.lang] = val;
            }
        },

        content: {
            get () {
                return this.contentInComponent[this.lang];
            },
            set (val) {
                this.contentInComponent[this.lang] = val;
            }
        },

        allowSave() {
            return (this.thumbnail || this.thumbnailFile) &&
                (this.titleImage || this.titleImageFile) &&
                this.titleInComponent &&
                this.titleInComponent["en"] &&
                this.titleInComponent["zh-hk"] &&
                this.titleInComponent["zh-cn"] &&
                this.contentInComponent &&
                this.contentInComponent["en"] &&
                this.contentInComponent["zh-hk"] &&
                this.contentInComponent["zh-cn"]
        }
    },
    methods: {
        onDragEnd() {
            this.edited = true
        },

        setImage() {
            this.isImageModalActive = true
        },

        setThumbnailImage() {
            this.isThumbnailModalActive = true
        },

        setTitleImage() {
            this.isTitleImageModalActive = true
        },

        revealImageElement(evt) {
            evt.currentTarget.parentElement.classList.toggle("active")
        },

        processTitleEditOperation(operation) {
            this.title = operation.api.origElements.innerHTML
        },

        contentImageHandler(file, Editor, cursorLocation, resetUploader) {
            console.log(file, cursorLocation)

            if (file instanceof File) {
                /* this.$store.dispatch("mall/addTestimonialContentImage", file)
                            this.images.push(file); */

                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        const res = Editor.insertEmbed(cursorLocation, "image", reader.result);
                        console.log(cursorLocation);
                        console.log(res);
                    },
                    false
                );
                reader.readAsDataURL(file);
            }
            resetUploader();
        },

        async save() {
            this.loading = true
            const testimonial = {
                "title": this.titleInComponent,
                "thumbnail": this.thumbnailFile,
                "titleImage": this.titleImageFile,
                "content": this.contentInComponent
            }
            let result
            try {
                result = await this.$store
                    .dispatch(
                        "mall/addNews",
                        testimonial
                    );
            } catch (e) {
                console.log(e)
                this.loading = false
                return
            }
            this.loading = false
            this.$router.push("/page/news");
        },

        async update() {
            this.loading = true
            let testimonial = {
                "_id": this._id,
                "title": this.titleInComponent,
                "content": this.contentInComponent
            }

            if (this.thumbnailFile) {
                testimonial.thumbnail = this.thumbnailFile
            }

            if (this.titleImageFile) {
                testimonial.titleImage = this.titleImageFile
            }

            let result
            try {
                result = await this.$store
                    .dispatch(
                        "mall/updateNews",
                        testimonial
                    );
            } catch (e) {
                console.error(e);
                this.loading = false;
                return;
            }
            this.loading = false;
            this.$router.push("/page/news");
        }
    },
    mounted() {
        const article = this.news
            .find(
                ({ _id }) => _id === this.newsId
            );

        if (article !== undefined) {
            this.mode = "Update";

            const {
                _id,
                title,
                content,
                thumbnail,
                titleImage,
            } = article;

            this._id = _id;
            this.titleInComponent = title;
            this.contentInComponent = content;
            this.thumbnail = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/mall/media/${ thumbnail }`;
            this.titleImage = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/mall/media/${ titleImage }`;
        }
    },
    watch: {
        "news" (newVal) {
            const article = newVal.find(
                ({ _id }) => _id === this.newsId
            );

            if (article !== undefined) {
                this.mode = "Update"

                const {
                    _id,
                    title,
                    content,
                    thumbnail,
                    titleImage,
                } = article;

                this._id = _id;
                this.titleInComponent = title;
                this.contentInComponent = content;
                this.thumbnail = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/mall/media/${ thumbnail }`;
                this.titleImage = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/mall/media/${ titleImage }`;
            }
        },

        "thumbnailFile" (newVal) {
            if (newVal instanceof File) {
                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        this.thumbnail = reader.result;
                    },
                    false
                );
                reader.readAsDataURL(newVal);
            }
        },

        "titleImageFile" (newVal) {
            if (newVal instanceof File) {
                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        this.titleImage = reader.result;
                    },
                    false
                );
                reader.readAsDataURL(newVal);
            }
        },

        "imageFiles" (newVal) {
            if (newVal.length && newVal.length > 0) {
                let temp = []
                newVal.forEach(
                    file => {
                        if (file instanceof File) {
                            const reader = new FileReader()
                            reader.addEventListener(
                                "load",
                                () => {
                                    temp.push(reader.result);
                                },
                                false
                            );
                            reader.readAsDataURL(file);
                        }
                    }
                )
                this.images = temp;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.message {
    margin-left: 25px;
    margin-right: 25px;
}

.testimonial-details {
    position: relative;
    background-color: rgba(245, 245, 245, 1);

    & > .tabs {
        margin-top: 1rem;
        margin-bottom: 1rem;

        & > ul {
            display: flex;
            justify-content: center;
        }
    }

    & > .container {
        background-color: rgba(255, 255, 255, 1);
    }

    .editable {
        position: relative;
        border-bottom: dashed 2px #8c67ef;

        &:after {
            position: absolute;
            bottom: -24px;
            right: 0;
            content: "Click to edit";
            font-size: 14px;
            font-weight: normal;
            padding: 3px;
        }
    }

    .button.edit {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .is-200x200 {
        height: 200px;
        width: 200px;
        background-color: rgba(113, 113, 113, 1);

        &.active {
            border: dashed 2px #ff1f4b;
        }
    }

    & > .testimonial {

    }
}
</style>
